import React, { useState } from 'react';
import {
  Box,
  Fade,
  InputBase,
  IconButton,
  useTheme,
  Button,
} from '@mui/material';
import { DetailType } from 'enums/global';
import { useDebounceFunction } from 'hooks/useDebounceFunction';
import { Close } from '@mui/icons-material';

const List = ({ setData, ...props }: any) => {
  const [values, setValues] = useState<{
    type: DetailType.list;
    listItems: any[];
  }>(props);

  const theme = useTheme();

  const debounceSetData = useDebounceFunction(setData, 400);

  const handleLiChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputIndex: number
  ) => {
    const valuesCopy = { ...values };
    valuesCopy.listItems[inputIndex] = {
      text: event.target.value,
    };
    setValues(valuesCopy);
    debounceSetData(valuesCopy);
  };

  const handleAddLi = () => {
    setValues((prev) => ({
      ...prev,
      listItems: [...prev.listItems, { text: '' }],
    }));
  };

  const handleDeleteLi = (inputIndex: number) => {
    setValues((prev) => ({
      ...prev,
      listItems: prev.listItems.filter((_, ind) => ind !== inputIndex),
    }));
  };

  return (
    <Box>
      <ul style={{ paddingLeft: 17 }}>
        {values.listItems.map((val, i) => (
          <Fade key={i} in timeout={300}>
            <li>
              <InputBase
                value={values.listItems[i]?.text || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleLiChange(event, i)
                }
                margin="dense"
                multiline
                style={{
                  width: 'calc(100% - 32px)',
                }}
              />
              <IconButton onClick={() => handleDeleteLi(i)} size="small">
                <Close fontSize="small" htmlColor={theme.palette.error.main} />
              </IconButton>
            </li>
          </Fade>
        ))}
        <li>
          <Button size="small" onClick={handleAddLi}>
            + Добавить пункт
          </Button>
        </li>
      </ul>
    </Box>
  );
};

export default List;
