import React, { useState } from 'react';
import { DocumentType, MediaType } from 'types/global';
import { Box, TextField, Typography } from '@mui/material';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import { Description } from '@mui/icons-material';

const Document = ({ setData, ...props }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<DocumentType>(props);
  const [name, setName] = useState<string>(props.name);

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName((prev) => {
      prev = e.target.value;
      return prev;
    });
    setData({
      ...file,
      name: e.target.value,
    });
  };

  const handleImageChange = (doc: MediaType) => {
    setFile((prev) => ({ ...prev, doc: doc, name: doc.name }));
    setName(doc.name);
    setOpen(false);
    setData({
      ...file,
      doc: doc,
    });
  };
  return (
    <>
      <Box
        boxSizing="border-box"
        width="100%"
        height="100%"
        borderRadius={24}
        mb={2}
      >
        <Typography>Файл</Typography>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          onClick={() => {
            setOpen(true);
          }}
        >
          <Description fontSize="large" />
          <Typography color="black">{file.doc?.name}</Typography>
        </Box>
        <Typography>Название файла</Typography>
        <TextField
          value={name}
          placeholder="Текст"
          onChange={handleChangePhone}
          style={{ marginBottom: 32, fontWeight: 600 }}
          fullWidth
          multiline
        />
      </Box>
      <SelectImageDialog
        endPoint={'/uploads/analysis'}
        open={open}
        setOpen={setOpen}
        onFileChange={handleImageChange}
      />
    </>
  );
};

export default Document;
