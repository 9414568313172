import React, { useEffect, useState } from 'react';

import AddStartBlockDialog from 'components/AddStartBlockDialog/AddStartBlockDialog';
import EmptyBlock from 'components/EmptyBlock/EmptyBlock';
import { RemoveBlockDialog } from 'components/RemoveBlockDialog/RemoveBlockDialog';
import { DeleteButton } from 'components/EmptyBlock/styles';
import { BlockListsWrapper } from 'pages/Blocks/components/styles';

import { CancelTwoTone } from '@mui/icons-material';

import { InfoBlockType } from 'types/global';

export type IProps = {
  data: InfoBlockType[];
  handleClick: (id: number) => () => void;
  disabled?: boolean;
  disabledReason?: string;
  setBanners?: React.Dispatch<React.SetStateAction<number[]>>;
  title: string;
};

const SelectAssignedItems: React.FC<IProps> = ({
  handleClick,
  data,
  disabled,
  disabledReason,
  setBanners,
  title,
}) => {
  const [bannersId, setBannersId] = useState<number[]>([]);
  const [isOpenAddBanners, setIsOpenAddBanners] = useState(false);
  const [previewBanners, setPreviewBanners] = useState<InfoBlockType[]>([]);

  const [openRemoveBlock, setOpenRemoveBlock] = useState(false);
  const [blockToRemoveId, setBlockToRemoveId] = useState(0);

  useEffect(() => {
    if (setBanners) {
      setBanners(bannersId);
    }
  }, [bannersId]);

  const handleToggleDialog = () => setIsOpenAddBanners((prev) => !prev);

  const handleCloseRemoveBlock = () => setOpenRemoveBlock((prev) => !prev);

  const handleOpenRemoveBlock = (id: number) => {
    setBlockToRemoveId(id);
    setOpenRemoveBlock((prev) => !prev);
  };

  const handleSave = (newIds: number[]) => {
    const filteredBanners = data.filter((obj) => newIds.includes(obj.id));
    setPreviewBanners(filteredBanners);
    setBannersId(newIds);
    setIsOpenAddBanners((prev) => !prev);
  };

  const handleDelete = (id: number) => {
    const filteredBanners = previewBanners.filter((obj) => obj.id !== id);
    const filteredIds = bannersId.filter((itemId) => itemId !== id);

    setBannersId(filteredIds);
    setPreviewBanners(filteredBanners);
  };

  return (
    <BlockListsWrapper>
      <AddStartBlockDialog
        title={title}
        open={isOpenAddBanners}
        onClose={handleToggleDialog}
        startIds={bannersId} // принимает массив ID
        allBlocks={data}
        onSave={handleSave}
      />
      <RemoveBlockDialog
        open={openRemoveBlock}
        onClose={handleCloseRemoveBlock}
        blockId={blockToRemoveId}
        onConfirm={handleDelete}
      />
      <EmptyBlock
        disabled={disabled}
        disabledReason={disabledReason}
        onClick={handleToggleDialog}
        describe="Выбрать"
      />
      {previewBanners.map(({ id, name, title, media, icon }: InfoBlockType) => (
        <div key={id} style={{ position: 'relative' }}>
          <DeleteButton onClick={() => handleOpenRemoveBlock(id)}>
            <CancelTwoTone color="error" />
          </DeleteButton>
          <EmptyBlock
            describe={name || title}
            imgSrc={media?.downloadUrl ?? icon?.downloadUrl}
            onClick={handleClick(id)}
          />
        </div>
      ))}
    </BlockListsWrapper>
  );
};

export default React.memo(SelectAssignedItems);
