/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Box } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';

/* Зачем?
    Если мы переходим на страницу с "тяжёлым" содержимым,
    то мы будем ждать, пока отрендерится это содержимое,
    а только потом перейдём на эту сраницу. Из-за этого возникает ощщущение 
    "подлаганности". С помощью этого хука мы можем избавиться от этого
    shouldRender означает, что переход на страницу осуществлён и можно начать рендер
*/

export const useShouldRender = (Component: any, frameCount = 20) => {
  const [shouldRender, setShouldRender] = useState<boolean>(false);

  const getAnimationFrame = (times: number) => {
    if (times > 0) {
      window.requestAnimationFrame(() => {
        getAnimationFrame(times - 1);
      });
    } else {
      setShouldRender(true);
    }
  };

  useEffect(() => {
    getAnimationFrame(frameCount);
  }, []);

  const renderComponent = useCallback(() => {
    if (shouldRender) {
      return <Component />;
    } else {
      return (
        <Box mt={2} mb={2} width="100%">
          Подождите...
        </Box>
      );
    }
  }, [shouldRender, Component]);
  return { renderComponent };
};
