import { Box, Button, Dialog, Grid2, Typography } from '@mui/material';
import React, { FC } from 'react';

interface IProps {
  open: boolean;
  blockId: number;
  onClose: () => void;
  onConfirm: (id: number) => void;
}

export const RemoveBlockDialog: FC<IProps> = ({
  open,
  blockId,
  onClose,
  onConfirm,
}) => {
  const handleConfirm = () => {
    onConfirm(blockId);
    onClose();
  };

  return (
    <Dialog onClose={() => onClose()} open={open}>
      <Grid2 container justifyContent="center" p={1.5}>
        <Typography variant="h6" textAlign="center">
          Вы действительно хотите удалить этот блок?
        </Typography>
        <Box width="100%" display="flex" justifyContent="space-between" m={2}>
          <Button onClick={handleConfirm} sx={{ ml: 3 }}>
            <Typography variant="body1" color="error">
              Удалить
            </Typography>
          </Button>
          <Button onClick={() => onClose()} sx={{ mr: 3 }}>
            <Typography variant="body1">Выйти</Typography>
          </Button>
        </Box>
      </Grid2>
    </Dialog>
  );
};
