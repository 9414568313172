import React, { ReactNode, useState } from 'react';

import { PaperWrapper } from 'HOC/PaperWrapper/PaperWrapper';
import BlockEditComponent from 'components/BlockEditComponent/BlockEditComponent';
import SelectAssignedItems from './SelectAssignedItems';

import useGetData from 'hooks/useGetData';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  TextField,
  Typography,
} from '@mui/material';

import { AxiosError, AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  terminalInput: Yup.string().required('Введите Id терминала'),
});

interface IAssignedTabs {
  requestGetData: (...args: any[]) => Promise<AxiosResponse<any, any>>;
  setAssignedReq: (
    terminalId: string,
    body: number[]
  ) => Promise<AxiosResponse<any, any>>;
  title: string;
}

const AssignedTabs: React.FC<IAssignedTabs> = ({
  requestGetData,
  setAssignedReq,
  title,
}) => {
  const [history, setHistory] = useState<(typeof data)[]>([]);
  const [bannerIds, setBannerIds] = useState<number[]>([]);

  const { data, loading, getData } = useGetData<any>({
    request: requestGetData,
  });

  const handleClick = (blockId: number) => () => {
    setHistory((prev) => [
      ...prev,
      data.find(({ id }: { id: number }) => id === blockId),
    ]);
  };

  if (loading) return <CircularProgress />;

  if (history.length) {
    return (
      <PaperWrapper editMode style={{ padding: 8 }}>
        <Typography variant="h6">Редактирование</Typography>
        <BlockEditComponent
          refetch={getData}
          history={history}
          blockData={history[0]}
          setHistory={setHistory}
        />
      </PaperWrapper>
    );
  }

  return (
    <Container maxWidth="md">
      <Formik
        initialValues={{ terminalInput: '' }}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (values, { resetForm, setErrors }) => {
          try {
            const response = await setAssignedReq(
              values.terminalInput,
              bannerIds
            );

            if (response.status === 200) {
              resetForm();
              window.location.reload();
            }
          } catch (e) {
            const _e = e as AxiosError<{ error: 'No terminalId' }>;
            if (_e.response?.data.error === 'No terminalId') {
              setErrors({ terminalInput: 'Не верный Id терминала' });
              console.log('Не верный Id терминала', _e.response.data);
            } else {
              console.error(e);
            }
          }
        }}
      >
        {({ handleSubmit, handleChange, values, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Box>
              <Typography variant="h6" mb={1}>
                ID терминала
              </Typography>
              <TextField
                label="Введите ID"
                variant="outlined"
                name="terminalInput"
                value={values.terminalInput}
                sx={{ marginBottom: '20px' }}
                onChange={handleChange}
                error={touched.terminalInput && Boolean(errors.terminalInput)}
                helperText={
                  touched.terminalInput && (errors.terminalInput as ReactNode)
                }
              />
            </Box>
            <Divider />

            <SelectAssignedItems
              title={title}
              handleClick={handleClick}
              data={data}
              setBanners={setBannerIds}
            />

            <Divider />
            <Box sx={{ marginTop: '20px' }}>
              <Button variant="contained" type="submit">
                Сохранить
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AssignedTabs;
