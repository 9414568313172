import { DeleteWrapper } from 'common/SelectImageDialog/styles';
import React from 'react';
import { LittleCloseIcon } from 'svgComponents/LittleCloseIcon';

const DeleteLabel = ({ onClick }: { onClick: () => void }) => {
  return (
    <DeleteWrapper onClick={() => onClick()}>
      <LittleCloseIcon />
    </DeleteWrapper>
  );
};

export default DeleteLabel;
