import { AppBar, Box, Drawer, ListItemButton, styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

const drawerWidth = 350;

export const NestedListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  borderLeft: '3px solid transparent',
  transition: '.3s',
}));

export const ActiveListItemButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderLeft: `3px solid ${theme.palette.primary.main}`,
  background: 'rgba(39, 140, 245, .1)',
  '& svg': {
    color: theme.palette.primary.main,
  },
  paddingLeft: theme.spacing(4),
  transition: '.3s',
}));

export const NavDrawerStyled = styled(Drawer)(() => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
}));

export const NavDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const StyledNavLink = styled(NavLink)(({ theme }) => ({
  height: '100%',
  padding: '0 16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  fontWeight: 400,
  borderBottom: '3px solid transparent',
  textTransform: 'none',
  textDecoration: 'none',

  '&.active': {
    fontWeight: 500,
    borderCollor: theme.palette.primary.main,
  },
}));

export const UserMenuWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: '0 0 0 auto',
}));

export const SideMenuWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginTop: theme.spacing(9),
  width: '100%',
}));
