import { styled } from '@mui/material';

export const StyledImgComponent = styled('img')(() => ({
  width: '100%',
  '&:hover': {
    cursor: 'pointer',
    transition: '.3s',
    filter: 'brightness(.7)',
  },
}));
