import { styled } from '@mui/material';

export const LabelStyled = styled('div')(() => ({
  fontSize: 18,
  fontWeight: 500,
  color: 'black',
  postiion: 'absolute',
  left: 0,
  top: '-10px',
}));
