import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { NavDrawer } from './NavDrawer';
import { UserMenu } from './UserMenu';
import { Container, Hidden, Typography } from '@mui/material';
import { SideMenu } from './SideMenu';
import { DRAWER_LINKS } from '_constants';
import { CustomLogo } from 'svgComponents/CustomLogo';
import {
  AppBarStyled,
  SideMenuWrapper,
  StyledNavLink,
  UserMenuWrapper,
} from './styles';
import { theme } from 'theme';
import { Menu } from '@mui/icons-material';
import host from 'host.json';

interface Props {
  children: React.ReactNode;
}

export const WithNavigation: React.FC<Props> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handlerDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div style={{ display: 'flex' }}>
      <CssBaseline />
      <AppBarStyled
        style={{ background: 'white' }}
        position="fixed"
        sx={
          isOpen
            ? {
                width: `calc(100% - 350px)`,
                marginLeft: 350,
                transition: theme.transitions.create(['margin', 'width'], {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              }
            : {}
        }
      >
        <Toolbar>
          <Hidden lgUp>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handlerDrawer}
              edge="start"
              sx={
                isOpen
                  ? { marginRight: theme.spacing(2), display: 'none' }
                  : { marginRight: theme.spacing(2) }
              }
            >
              <Menu />
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <div style={{ marginRight: 32 }}>
              <CustomLogo />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {DRAWER_LINKS.map(
                (item) =>
                  host.features.includes(item.type) && (
                    <StyledNavLink to={item.link} key={item.link}>
                      {item.title}
                    </StyledNavLink>
                  )
              )}
            </div>
          </Hidden>
          <UserMenuWrapper>
            <Typography
              variant="body2"
              color="primary"
              style={{ marginLeft: 'auto' }}
            >
              {host.version}
            </Typography>
            <UserMenu />
          </UserMenuWrapper>
        </Toolbar>
      </AppBarStyled>

      {/* mobile drawer */}
      <NavDrawer open={isOpen} handleDrawerClose={handlerDrawer} />

      <SideMenuWrapper>
        <SideMenu />
        <Container>
          <>
            {/* desktop side menu */}
            <main
              style={
                isOpen
                  ? {
                      flexGrow: 1,
                      padding: theme.spacing(3),
                      transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                      }),
                      marginLeft: 0,
                    }
                  : {
                      flexGrow: 1,
                      padding: theme.spacing(3),
                      transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                    }
              }
            >
              {children}
            </main>
          </>
        </Container>
      </SideMenuWrapper>
    </div>
  );
};
