import React, { useRef } from 'react';

import {
  Fade,
  Typography,
  TableRow,
  TableCell,
  Box,
  Button,
} from '@mui/material';

import CreateInfoBannerCard from 'components/CreateInfoBannerCard/CreateInfoBannerCard';
import { DataTable } from 'common/DataTable/DataTable';

import { ITableBodyRenderer } from 'interface/global';
import { IAllGuests } from 'interface/guests';

const emptyBanner = {
  id: -1,
  media: null,
  title: '',
  subTitle: '',
  isActive: true,
};

const dataTableHeaders = [
  'ID',
  'Заголовок',
  'Подзаголовок',
  'Детальный экран',
  'Активен',
];

export type refetchType = (() => void) | null;
type setCardType = React.Dispatch<any> | null;

interface Irow {
  id: number;
  title: string;
  subTitle: string;
  lastUpdate: string;
  isActive: boolean;
  media: any;
  detailsScreenId: number;
  detailsScreen: DetailsScreen;
}

interface DetailsScreen {
  id: number;
  title: string | null;
  uploadTime: string;
  action?: any;
  data: any[];
}

interface IInfoBanners {
  endPoint: string;
  endPointForDialog?: string;
  title?: string;
}

const InfoBanners: React.FC<IInfoBanners> = ({
  endPoint,
  endPointForDialog,
  title,
}) => {
  const refetch = useRef<refetchType>(null);
  const setCard = useRef<setCardType>(null);

  const dataTableHandler: ITableBodyRenderer<IAllGuests> = (
    data: any,
    setSelectedRowInfo,
    refetchRq
  ) => {
    if (refetchRq && setSelectedRowInfo) {
      refetch.current = refetchRq;
      setCard.current = setSelectedRowInfo;
    }

    return data?.data?.map((row: Irow) => (
      <TableRow
        key={row.id}
        hover
        role="checkbox"
        sx={{ cursor: 'pointer' }}
        onClick={() => setSelectedRowInfo && setSelectedRowInfo(row)}
      >
        <TableCell scope="row">{row.id}</TableCell>
        <TableCell align="left">{row.title}</TableCell>
        <TableCell align="left">{row.subTitle}</TableCell>
        <TableCell align="left">{row.detailsScreen?.title || ''}</TableCell>
        <TableCell align="left">{row.isActive ? 'Да' : 'Нет'}</TableCell>
      </TableRow>
    ));
  };

  return (
    <Fade in timeout={500}>
      <div>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h2" m={0}>
            {title}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            sx={{ marginLeft: '32px' }}
            onClick={() => setCard?.current?.(emptyBanner)}
          >
            Создать
          </Button>
        </Box>

        <DataTable
          header={dataTableHeaders}
          bodyRenderer={dataTableHandler}
          endPoint={endPoint}
          renderSideCard={(selectedRowInfo) =>
            selectedRowInfo ? (
              <CreateInfoBannerCard
                refresh={refetch.current}
                selectedRowInfo={selectedRowInfo}
                endPointForDialog={endPointForDialog}
              />
            ) : null
          }
        />
      </div>
    </Fade>
  );
};

export default InfoBanners;
