import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { IconItem } from './IconItem';
import { useIconWithPicker } from './useIconWithPicker';
import { MediaType } from 'types/global';
import { AddButton } from 'compounds/FilesList/AddButton';
import DebouncedSearch from './DebouncedSearch';
import { Pagination } from 'common/Pagination/Pagination';
import { IconList } from './styles';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onFileChange: (icon: MediaType) => void;
  endPoint?: string;
}

export const SelectImageDialog: React.FC<Props> = ({
  open,
  setOpen,
  onFileChange,
  endPoint = '/uploads/images',
}: Props) => {
  const [page, setPage] = useState(1);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    type,
    isIconsFetching,
    iconList,
    handleUploadInputChange,
    isIconUploading,
    handleDeleteIcon,
    limit,
    handlePageChange,
    handleSearchChange,
  } = useIconWithPicker({ onFileChange, endPoint });

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <DialogContent sx={{ p: 4 }}>
        {isIconUploading && (
          <Box>
            <Typography>Загрузка файла...</Typography>
            <CircularProgress color="primary" />
          </Box>
        )}
        <DebouncedSearch outerValue="" setValue={handleSearchChange} />
        {isIconsFetching ? (
          <Box width="100%" height=" 100%" textAlign="center">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <div>
            <IconList>
              <label>
                <AddButton noLabel size={80} />
                <input
                  hidden
                  type="file"
                  accept={
                    type === 'IMAGE'
                      ? '.jpg, .png, .jpeg, .gif, .bmp, .tif'
                      : '.doc, .docx, .pdf'
                  }
                  className="none"
                  onChange={handleUploadInputChange}
                />
              </label>
              {iconList.map((icon) => (
                <IconItem
                  icon={icon}
                  key={icon.uuid}
                  imageType={type}
                  onClick={onFileChange}
                  onDelete={handleDeleteIcon}
                />
              ))}
            </IconList>
          </div>
        )}
        <Pagination
          onPageChange={(page: number) => {
            handlePageChange(page);
            setPage(page);
          }}
          totalCount={iconList.length === limit ? page + 1 : page}
          page={page}
        />
      </DialogContent>
    </Dialog>
  );
};
