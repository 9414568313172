import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import TextClamp from 'react-string-clamp';
import { EmptyBlockWrapper } from './styles';
import { Add } from '@mui/icons-material';

type EmptyBlockProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  imgSrc?: string;
  describe?: string;
  disabled?: boolean;
  disabledReason?: string;
};

const EmptyBlock: FC<EmptyBlockProps> = ({
  onClick,
  imgSrc,
  describe,
  disabled,
  disabledReason,
}) => {
  const theme = useTheme();

  const handleClick = () => {
    if (disabled) return () => undefined;
    return onClick;
  };

  return (
    <Tooltip
      placement="top"
      title={disabledReason || ''}
      disableHoverListener={!disabled}
    >
      <div>
        <EmptyBlockWrapper
          onClick={handleClick()}
          sx={{
            cursor: disabled ? 'default' : 'pointer',
          }}
        >
          {imgSrc ? (
            <img
              src={imgSrc as string}
              alt="photo"
              style={{
                border: `2px solid ${theme.palette.primary.main}`,
              }}
            />
          ) : (
            <Add
              style={{
                color: theme.palette.primary.main,
                border: `2px dashed ${theme.palette.primary.main}`,
              }}
            />
          )}
          <TextClamp lines={3} text={describe || 'Создать'} />
        </EmptyBlockWrapper>
      </div>
    </Tooltip>
  );
};

export default EmptyBlock;
