import React, { useState } from 'react';
import { Fade, Grid2 } from '@mui/material';
import { QueryFilter } from './QueryFilter';
import { List } from './List';
import { ITableBodyRenderer } from 'interface/global';

interface Props {
  queryFilter?: React.ReactNode;
  header: string[];
  bodyRenderer: ITableBodyRenderer;
  renderSideCard?: (
    selectedRowInfo: number | null
  ) => Element | JSX.Element | null;
  endPoint: string;
}

const DataTable = ({
  queryFilter = <QueryFilter />,
  header,
  bodyRenderer,
  renderSideCard,
  endPoint,
}: Props): JSX.Element => {
  const [selectedRowInfo, setSelectedRowInfo] = useState<number | null>(null);
  return (
    <>
      {queryFilter}

      <Grid2 container justifyContent="space-between" spacing={2}>
        <Grid2
          size={{
            md: renderSideCard ? 7 : 12,
            lg: renderSideCard ? 7 : 12,
          }}
        >
          <List
            setSelectedRowInfo={setSelectedRowInfo}
            header={header}
            endPoint={endPoint}
            bodyRenderer={bodyRenderer}
          />
        </Grid2>
        {!!renderSideCard && (
          <Grid2
            size={{
              md: 5,
              lg: 5,
            }}
          >
            <Fade in={selectedRowInfo !== null} timeout={500}>
              <div style={{ position: 'sticky', top: 89 }}>
                {renderSideCard(selectedRowInfo)}
              </div>
            </Fade>
          </Grid2>
        )}
      </Grid2>
    </>
  );
};

export { DataTable };
