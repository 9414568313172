/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { instance } from 'api/instance';
import { doRequest } from 'hooks/doRequest';
import React, { useMemo } from 'react';
import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { MediaType } from 'types/global';

interface Props {
  onFileChange: (icon: MediaType) => void;
  endPoint: string;
  query?: Record<string, any>;
}

export const useIconWithPicker = (props: Props) => {
  const [isIconsFetching, setIsIconsFetching] = useState<boolean>(true);
  const [isIconUploading, setIconUploading] = useState<boolean>(false);
  const [iconList, setIconList] = useState<MediaType[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);
  const [query, setQuery] = useState('');

  const { current: limit } = useRef(15);
  const type = useMemo<'ICON' | 'IMAGE' | 'DOC'>(
    () =>
      props.endPoint.includes('icons')
        ? 'ICON'
        : props.endPoint.includes('/images')
        ? 'IMAGE'
        : 'DOC',
    [props.endPoint]
  );

  const handlePageChange = (page: number) => {
    setOffset(page * limit);
  };

  const handleSearchChange = (search: string) => {
    setQuery(search);
  };

  const getIcons = async () =>
    instance.get(props.endPoint, { params: { query, offset, limit } });

  const postUploadIcon = (data: {
    filename: string;
    ext: string;
    requestFile: File;
  }) => {
    return instance.post(
      `${props.endPoint}/${data.filename}/new?ext=${data.ext}`,
      data.requestFile
    );
  };

  const postDeleteIcon = (path: URLSearchParams) =>
    instance.delete(props.endPoint, { data: path });

  const getIconsList = async (query?: Record<any, any>) => {
    setIsIconsFetching(true);
    const res = await doRequest<MediaType[]>(getIcons, query);

    if (res.success) {
      setIconList(res.data);
    } else {
      setError(true);
    }
    setIsIconsFetching(false);
  };

  const handleUploadInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      setIconUploading(true);
      const currentFile = event.target.files[0];
      const res = await doRequest<string>(postUploadIcon, {
        requestFile: currentFile,
        filename: currentFile.name
          .split('.')
          .slice(0, currentFile.name.split('.').length - 1)
          .join(' '),
        ext: currentFile.name
          .split('.')
          .slice(currentFile.name.split('.').length - 1)[0],
      });
      if (res.success) {
        getIconsList();
        toast.success('Файл загружен');
        setIconUploading(false);
      } else {
        toast.error('Ошибка загрузки файла');
        setIconUploading(false);
      }
    }
  };

  const handleDeleteIcon = async (icon: MediaType) => {
    const fileName = icon.name.split('/').slice(-1)?.[0];
    const myPath = new URLSearchParams();
    myPath.append('path', fileName);
    await doRequest(postDeleteIcon, myPath);
    getIconsList();
  };

  useEffect(() => {
    getIconsList();
  }, [query, offset]);

  return {
    isIconUploading,
    isIconsFetching,
    iconList,
    error,
    handleUploadInputChange,
    handleDeleteIcon,
    handlePageChange,
    handleSearchChange,
    getIconsList,
    offset,
    limit,
    type,
  };
};
