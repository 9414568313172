import { Chip } from '@mui/material';
import { FC, useState } from 'react';
import React from 'react';

interface Props {
  id: number;
  onClick: (id: number) => void;
  selectedDays: number[];
}

const daysDict: Record<number, string> = {
  1: 'Понедельник',
  2: 'Вторник',
  3: 'Среда',
  4: 'Четверг',
  5: 'Пятница',
  6: 'Суббота',
  7: 'Воскресенье',
};

const SelectableChip: FC<Props> = ({ id, onClick, selectedDays }) => {
  const [isSelected, setIsSelected] = useState(selectedDays.includes(id));
  const handleClick = () => {
    if (isSelected) {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
    onClick(id);
  };
  return (
    <Chip
      label={daysDict[id]}
      onClick={handleClick}
      color={isSelected ? 'primary' : 'default'}
    />
  );
};

export default SelectableChip;
