import { PaperWrapper } from 'HOC/PaperWrapper/PaperWrapper';
import React, { useState, useEffect } from 'react';
import {
  Fade,
  Typography,
  CircularProgress,
  Box,
  Button,
  ListItemButton,
} from '@mui/material';
import { doRequest } from 'hooks/doRequest';
import { deleteEditDetailsScreen, getAllDetails } from 'api/sections';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { Route, Switch, useHistory } from 'react-router';
import DetailEdit from 'components/DetailEdit/DetailEdit';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import { DeleteIcon } from 'svgComponents/DeleteIcon';
import DeleteDialog from 'components/DeleteDialog/DeleteDialog';
import { DetailDataType } from 'types/global';

const EndScreens = ({ pathname }: { pathname: string }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<DetailDataType[]>([]);
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [selectedDetailId, setSelectedDetailId] = useState(-1);
  const [selectedDetailName, setSelectedDetailName] = useState('');
  const [inBlock, setInBlock] = useState(false);

  const handleClickOpen = (id: number) => {
    let block: DetailDataType = { id: -1, title: '', data: [], uploadTime: '' };
    const foundBlock = data?.find((d: DetailDataType) => d.id === id);
    if (foundBlock) {
      block = foundBlock;
    }
    setSelectedDetailName(block?.title);
    setSelectedDetailId(id);
    setOpen(true);
  };

  const handleClickOpenInBlock = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllDetailsRequest = async () => {
    setIsFetching(true);
    const result = await doRequest<any | null>(getAllDetails);
    setIsFetching(false);
    setData(result.data?.data);
  };

  useEffect(() => {
    getAllDetailsRequest();
  }, []);

  const handleClick = (id: number) => () => {
    setSelectedDetailId(id);
    let block: DetailDataType = { id: -1, title: '', data: [], uploadTime: '' };
    const foundBlock = data?.find((d: DetailDataType) => d.id === id);
    if (foundBlock) {
      block = foundBlock;
    }
    setSelectedDetailName(block?.title);
    setInBlock(true);
    history.push(`${history.location.pathname}/${id}`);
  };

  const handleBack = () => {
    setInBlock(false);
    const route = history.location.pathname.split('/');
    history.push(`${route.slice(0, route.length - 1).join('/')}`);
  };

  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Детальные экраны</Typography>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <PaperWrapper>
            <Switch>
              <Route exact path={`${pathname}`}>
                <Box>
                  <DeleteDialog
                    open={open}
                    onClose={handleClose}
                    id={selectedDetailId}
                    name={selectedDetailName}
                    inBlock={inBlock}
                    onDelete={deleteEditDetailsScreen}
                  />
                  <BrandButton
                    variant="contained"
                    color="primary"
                    onClick={handleClick(-1)}
                  >
                    Создать
                  </BrandButton>
                  <List>
                    {data.map((i: any) => (
                      <Box key={i.id} display="flex">
                        <ListItemButton
                          onClick={handleClick(i.id)}
                          id={i.id}
                          key={i.id}
                        >
                          <ListItemText primary={i.title} />
                        </ListItemButton>
                        <Button onClick={handleClickOpen.bind(null, i.id)}>
                          <DeleteIcon />
                        </Button>
                      </Box>
                    ))}
                  </List>
                </Box>
              </Route>
              <Route path={`${pathname}/:blockId`}>
                <Box>
                  <DeleteDialog
                    open={open}
                    onClose={handleClose}
                    id={selectedDetailId}
                    name={selectedDetailName}
                    inBlock={inBlock}
                    onDelete={deleteEditDetailsScreen}
                  />
                  <Box display="flex" alignItems="center" style={{ gap: 2 }}>
                    <BrandButton
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                    >
                      Назад
                    </BrandButton>
                    <Button
                      style={{ marginLeft: '16px' }}
                      onClick={handleClickOpenInBlock}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 4 }}
                      >
                        <Typography variant="body1" color="error">
                          Удалить
                        </Typography>
                        <DeleteIcon />
                      </Box>
                    </Button>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <DetailEdit allData={data} />
                  </Box>
                </Box>
              </Route>
            </Switch>
          </PaperWrapper>
        )}
      </div>
    </Fade>
  );
};

export default EndScreens;
