import { Button, styled } from '@mui/material';

export const EmptyBlockWrapper = styled('div')(() => ({
  minWidth: 100,
  width: 100,
  maxWidth: 100,
  borderRadius: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  wordBreak: 'break-all',
  textAlign: 'center',
  transition: '.2s',
  backgorund: 'white',
  position: 'relative',

  '&:hover': {
    background: 'rgba(0,0,0, .1)',

    '& > img': {
      filter: 'brightness(0.7)',
    },
  },

  '& > *:first-child': {
    minHeight: 100,
    minWidth: 100,
    width: 100,
    height: 100,
    maxWidth: 100,
    maxHeight: 100,
    borderRadius: 10,

    '&:not(svg)': {
      objectFit: 'cover',
    },

    '& + div': {
      marginTop: 8,
      maxHeight: 100,
    },
  },
}));

export const DeleteButton = styled(Button)(() => ({
  right: '-12px',
  top: '-8px',
  position: 'absolute',
  zIndex: 10,
  minWidth: '24px',
  width: '24px',
  height: '24px',
}));
