/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material';

const BrandTextFieldFormik = (props) => {
  const {
    value = '',
    onChange,
    error,
    helperText,
    formik,
    ...otherProps
  } = props;

  const name = otherProps.name || '';
  return (
    <MuiStyledTextField
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      variant="filled"
      {...otherProps}
    />
  );
};

const BrandTextField = (props) => {
  return <MuiStyledTextField variant="filled" {...props} />;
};

const MuiStyledTextField = styled(MuiTextField)(({ theme }) => ({
  border: `1px solid ${theme.palette.info.main}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: 'transparent',
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  caretColor: theme.palette.primary.main,
  '&:hover': {
    borderColor: theme.palette.primary.light,
    background: 'transparent',
  },
  '&.Mui-focused': {
    backgroundColor: '#fff',
    boxShadow: `none`,
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
  },
  '&.Mui-error': {
    background: 'rgba(255,0,0,.2)',
    borderColor: 'rgba(255,0,0,.2)',
    '&:hover': {
      background: 'rgba(255,0,0,.05)',
      borderColor: 'rgba(255,0,0,.1)',
    },
    '&.Mui-focused': {
      boxShadow: 'none',
      background: 'rgba(255,0,0,.2)',
      borderColor: 'rgba(255,0,0,.2)',
      borderWidth: '2px',
    },
  },
}));

export { BrandTextField, BrandTextFieldFormik };
