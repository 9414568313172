/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { ITableBodyRenderer } from 'interface/global';
import React from 'react';
import { TableLoader } from 'ui/TableLoader/TableLoader';
import { useHandleList } from './hooks/useHandleList';
import { ListPagination } from './ListPagination';
import { useEffect } from 'react';

interface Props {
  header: string[];
  setSelectedRowInfo: React.Dispatch<any>;
  endPoint: string;
  bodyRenderer: ITableBodyRenderer;
}

const List = ({
  header,
  setSelectedRowInfo,
  endPoint,
  bodyRenderer,
}: Props): JSX.Element => {
  const { data, isFetching, refetch } = useHandleList<
    Record<string, unknown> & { totalCount?: number }
  >({
    endPoint,
  });

  useEffect(() => {
    setSelectedRowInfo((prev: any) => {
      if (prev?.id !== -1) {
        return (data?.data as any)?.find?.((i: any) => i.id === prev?.id);
      }
      return prev;
    });
  }, [data]);

  return (
    <TableContainer style={{ position: 'relative' }} component={Paper}>
      {isFetching && <TableLoader />}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {header.map((cell) => (
              <TableCell key={cell}>{cell}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          style={{
            filter: `blur(${isFetching ? '7px' : '0px'})`,
          }}
        >
          {!!data && bodyRenderer(data, setSelectedRowInfo, refetch)}
        </TableBody>
      </Table>
      <ListPagination totalCount={data?.totalCount || 1} />
    </TableContainer>
  );
};

export { List };

/* 
          data.result.map((row: IGuest) => {
              const fio = `${row.surname} ${row.name} ${row.patronymic}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setSelectedGuest(row.id)}
                  key={fio}
                >
                  <TableCell scope="row">{row.id}</TableCell>
                  <TableCell align="left">{fio}</TableCell>
                  <TableCell align="left">{row.roomNumber}</TableCell>
                </TableRow>
              );
            }) 
            */
