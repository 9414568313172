import React from 'react';
import { TextField } from '@mui/material';
import { Grid2 } from '@mui/material';
import { Labeled } from 'ui/Labeled/Labeled';
import { useHandleSelect } from 'hooks/useHandleSelect';
import { Autocomplete } from '@mui/lab';
import { useHandleChange } from 'hooks/useHandleChange';

export const ProfileFields: React.FC = () => {
  const [localFioValue, handleFioChange] = useHandleChange(undefined, '');

  const [localEmailValue, handleEmailChange] = useHandleChange(undefined, '');

  const [localSpecValue, handleSpecSelect] = useHandleSelect(null, null);

  const [localWorkPlaceValue, handleWorkPlaceSelect] = useHandleSelect(
    null,
    null
  );

  return (
    <>
      <Grid2
        sx={{
          xs: 12,
          md: 6,
        }}
      >
        <Labeled label="ФИО">
          <TextField
            placeholder="Введите ФИО..."
            variant="outlined"
            size="small"
            type="text"
            margin="dense"
            value={localFioValue}
            onChange={handleFioChange}
            fullWidth
            style={{ maxWidth: 360 }}
          />
        </Labeled>
      </Grid2>
      <Grid2
        sx={{
          xs: 12,
          md: 6,
        }}
      >
        <Labeled label="Специализация">
          <Autocomplete
            onChange={handleSpecSelect}
            value={localSpecValue}
            options={[
              { id: 1, title: 'Специализация' },
              { id: 2, title: 'Специализация 1' },
            ]}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Введите специализацию"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                style={{ maxWidth: 360 }}
              />
            )}
          />
        </Labeled>
      </Grid2>
      <Grid2
        sx={{
          xs: 12,
          md: 6,
        }}
      >
        <Labeled label="E-mail">
          <TextField
            variant="outlined"
            size="small"
            margin="dense"
            value={localEmailValue}
            onChange={handleEmailChange}
            type="email"
            fullWidth
            style={{ maxWidth: 360 }}
          />
        </Labeled>
      </Grid2>
      <Grid2
        sx={{
          xs: 12,
          md: 6,
        }}
      >
        <Labeled label="Место работы">
          <Autocomplete
            onChange={handleWorkPlaceSelect}
            value={localWorkPlaceValue}
            options={[
              { id: 1, title: 'Место работы' },
              { id: 2, title: 'Мест работы 2' },
            ]}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Введите место работы"
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
                style={{ maxWidth: 360 }}
              />
            )}
          />
        </Labeled>
      </Grid2>
    </>
  );
};
