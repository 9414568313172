import { Paper } from '@mui/material';
import React from 'react';

interface Props extends Record<any, any> {
  children: React.ReactNode;
  editMode?: boolean;
}

export const PaperWrapper: React.FC<Props> = ({
  children,
  editMode,
  ...other
}: Props) => {
  return editMode ? (
    <Paper style={{ overflow: 'hidden' }} {...other}>
      {children}
    </Paper>
  ) : (
    <div {...other}>{children}</div>
  );
};
