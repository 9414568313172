import React, { FC, useEffect, useState } from 'react';

import EmptyBlock from 'components/EmptyBlock/EmptyBlock';

import { Box, Button, Dialog, Grid, Typography } from '@mui/material';

import { SelectedBorder } from './style';
import { InfoBlockType } from 'types/global';

interface Props {
  open: boolean;
  onClose: () => void;
  startIds: number[];
  allBlocks: InfoBlockType[];
  onSave: (newIds: number[]) => void;
  title: string;
}

const AddStartBlockDialog: FC<Props> = ({
  open,
  onClose,
  startIds,
  allBlocks,
  onSave,
  title,
}) => {
  const [selectedBlocksIds, setSelectedBlocksIds] = useState<number[]>([]);

  useEffect(() => {
    if (startIds.length) {
      setSelectedBlocksIds((prev) =>
        prev.filter((item) => startIds.includes(item))
      );
    } else {
      setSelectedBlocksIds([]);
    }
  }, [startIds]);

  const handleClose = () => onClose();

  const handleSaveBlocks = () => onSave(selectedBlocksIds);

  const handleClick = (id: number) => {
    if (selectedBlocksIds.includes(id)) {
      setSelectedBlocksIds((prev) => prev.filter((itemId) => itemId !== id));
    } else {
      setSelectedBlocksIds((prev) => [...prev, id]);
    }
  };

  const handleSelect = ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
    if (currentTarget.style.border === '3px solid green') {
      currentTarget.style.border = '0px';
    } else {
      currentTarget.style.border = '3px solid green';
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        alignContent="center"
        flexWrap="wrap"
        gap={2}
        p={2}
      >
        <Typography variant="h6" textAlign="center" mb={1}>
          {title}
        </Typography>
        <Box
          display="flex"
          gap={1}
          flexWrap="wrap"
          sx={{ maxHeight: '300px', overflowY: 'auto' }}
        >
          {allBlocks !== null &&
            allBlocks.map(({ id, name, title, media, icon }) => {
              if (startIds.includes(id)) {
                return null;
              } else {
                return (
                  <SelectedBorder
                    key={id}
                    onClick={handleSelect}
                    sx={{ height: '100%' }}
                  >
                    <div style={{ position: 'relative' }}>
                      <EmptyBlock
                        describe={name || title}
                        imgSrc={media?.downloadUrl ?? icon?.downloadUrl}
                        onClick={() => handleClick(id)}
                      />
                    </div>
                  </SelectedBorder>
                );
              }
            })}
        </Box>
        <Box width="100%" display="flex" justifyContent="space-between" m={2}>
          <Button onClick={handleSaveBlocks} sx={{ ml: 3 }}>
            <Typography variant="body1" color="primary">
              Сохранить
            </Typography>
          </Button>
          <Button onClick={() => onClose()} sx={{ mr: 3 }}>
            <Typography variant="body1">Выйти</Typography>
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
};

export default AddStartBlockDialog;
