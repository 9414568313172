import { Box, styled } from '@mui/material';

export const SelectedBorder = styled(Box)(() => ({
  width: '120px',
  height: '120px',
  border: '0px',
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '8px',
  borderRadius: '12px',
}));
