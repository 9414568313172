import React from 'react';
import { Pagination as MuiPagination } from '@mui/lab';
import { Box } from '@mui/material';

interface Props {
  totalCount?: number;
  onPageChange?: (newPage: number) => void;
  onRowChange?: (newRow: number) => void;
  page: number;
}

export const Pagination: React.FC<Props> = ({
  totalCount = 1,
  onPageChange,
  page,
}) => {
  const handlePageChange = (_: any, value: number) => {
    if (onPageChange) {
      onPageChange(value);
    }
  };

  return (
    <Box sx={{ p: 2 }} display="flex" justifyContent="center">
      <MuiPagination
        variant="outlined"
        color="primary"
        shape="rounded"
        count={totalCount}
        page={page}
        onChange={handlePageChange}
      />
    </Box>
  );
};
