export enum InfoBlockEnum {
  image = 'IMAGE',
  roomCategory = 'ROOM_CATEGORY',
  room = 'ROOM',
  icon = 'ICON',
}

export enum DetailType {
  header = 'ROOM_HEADER',
  title = 'TITLE',
  subtitle = 'SUB_TITLE',
  paragraph = 'PARAGRAPH',
  image = 'IMAGE',
  list = 'LIST',
  gallery = 'GALLERY',
  link = 'LINK',
  icons = 'ICONS',
  phone = 'PHONE',
  document = 'DOCUMENT',
}

export const INFO_BLOCK_ENUM_VALUES = [
  { id: InfoBlockEnum.image, title: 'Изображение' },
  { id: InfoBlockEnum.roomCategory, title: 'Категория номеров' },
  { id: InfoBlockEnum.room, title: 'Номер' },
  { id: InfoBlockEnum.icon, title: 'Иконка' },
];

/* RoomCategory
Room
Icon
Image */
