import { Box, styled } from '@mui/material';
import { Grid2 } from '@mui/material';

export const TimeWrapper = styled(Box)(() => ({
  marginTop: '16px',
  display: 'flex',
  flexDirection: 'column',
  width: '30%',
  gap: 16,
}));

export const ChipsWrapper = styled(Grid2)(() => ({
  width: '50%',
  border: 'solid 2px',
  borderColor: 'gray',
  padding: 8,
  borderRadius: '8px',
}));
