import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { ActiveListItemButton, NestedListItemButton } from './styles';
import { useState } from 'react';
import { DRAWER_LINKS } from '_constants';
import { useHistory } from 'react-router';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export const LinkList: React.FC = () => {
  const [isOpen, setIsOpen] = useState<Record<number, boolean>>({});

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {renderMarkup(isOpen, setIsOpen)}
    </List>
  );
};

function renderMarkup(
  isOpen: Record<number, boolean>,
  setIsOpen: React.Dispatch<React.SetStateAction<Record<number, boolean>>>
) {
  const history = useHistory();
  const markup: unknown[] = [];

  DRAWER_LINKS.forEach((item, index) => {
    const { Icon } = item;
    markup.push(
      <ListItemButton
        key={item.link}
        onClick={() =>
          item.menu
            ? setIsOpen((prevState) => ({
                ...prevState,
                [index]: !isOpen[index],
              }))
            : history.push(item.link)
        }
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={item.title} />
        {item.menu ? isOpen[index] ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
    );
    if (item.menu) {
      markup.push(
        <Collapse key={index} in={!!isOpen[index]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.menu.map((menuItem) =>
              history.location.pathname.includes(menuItem.link) ? (
                <ActiveListItemButton
                  key={menuItem.link}
                  onClick={() => history.push(menuItem.link)}
                >
                  <ListItemIcon>{(() => menuItem.icon)()}</ListItemIcon>
                  <ListItemText primary={menuItem.title} />
                </ActiveListItemButton>
              ) : (
                <NestedListItemButton
                  key={menuItem.link}
                  onClick={() => history.push(menuItem.link)}
                >
                  <ListItemIcon>{(() => menuItem.icon)()}</ListItemIcon>
                  <ListItemText primary={menuItem.title} />
                </NestedListItemButton>
              )
            )}
          </List>
        </Collapse>
      );
    }
  });

  return markup;
}
