import axios from 'axios';
import host from 'host.json';

//const poltavaServerHost = 'https://admin.resort.api.famedev.ru/';

export const instance = axios.create({
  /*PROD*/
  /*baseURL: 'https://api.guest.poltava.prod.famedev.ru/',*/
  /*STAGE*/
  baseURL: host.hostUrl,
  withCredentials: true,
});

instance.interceptors.request.use(
  function (config) {
    const jwt = localStorage.getItem('jwt');

    if (jwt) {
      config.headers['Authorization'] = `Bearer ${jwt}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
