import React, { useState } from 'react';

import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import Image from 'ui/Image/Image';

import { MediaType } from 'types/global';

interface SelectMediaBannerI {
  value: MediaType | null;
  setValue: (media: MediaType) => void;
  endPointForDialog?: string;
}

const SelectMediaBanner: React.FC<SelectMediaBannerI> = ({
  value,
  setValue,
  endPointForDialog,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen((prev) => !prev);

  const handleSelectFile = (media: MediaType) => {
    setIsOpen((prev) => !prev);
    setValue(media);
  };

  return (
    <>
      <Image onClick={handleClose} src={value?.downloadUrl} />
      <SelectImageDialog
        open={isOpen}
        setOpen={setIsOpen}
        onFileChange={handleSelectFile}
        endPoint={endPointForDialog}
      />
    </>
  );
};

export default SelectMediaBanner;
