import { IconButton, useTheme, Typography } from '@mui/material';
import React, { useState } from 'react';
import { EditWisiwygBoxWrapper, ToolbarWrapper } from './styles';
import {
  ArrowDownwardRounded,
  DeleteForeverOutlined,
} from '@mui/icons-material';

interface Props {
  children: React.ReactNode;
  handleDeleteBlock?: () => void;
  editMode: boolean;
  handleMoveUp?: () => void;
  handleMoveDown?: () => void;
  name?: string;
}

export const EditWisiwygBox: React.FC<Props> = ({
  children,
  handleDeleteBlock,
  handleMoveUp,
  handleMoveDown,
  name,
  editMode = false,
}: Props) => {
  const [hover, setHover] = useState<boolean>(false);
  const theme = useTheme();

  const handleMouseEnter = () => {
    setHover(true);
  };
  const handleMouseLeave = () => {
    setHover(false);
  };

  return editMode ? (
    <EditWisiwygBoxWrapper
      sx={{
        border: hover ? '1px solid rgba(0,0,0,.1)' : '1px solid transparent',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Toolbar>
        <Typography style={{ margin: '0 auto 0 8px' }}>
          {!!name && name}
        </Typography>
        <IconButton size="small" onClick={handleDeleteBlock}>
          <DeleteForeverOutlined
            fontSize="small"
            htmlColor={theme.palette.error.main}
          />
        </IconButton>
        {!!handleMoveUp && (
          <IconButton size="small" onClick={handleMoveUp}>
            <ArrowDownwardRounded fontSize="small" />
          </IconButton>
        )}
        {!!handleMoveDown && (
          <IconButton size="small" onClick={handleMoveDown}>
            <ArrowDownwardRounded
              style={{ transform: 'rotate(180deg)' }}
              fontSize="small"
            />
          </IconButton>
        )}
      </Toolbar>
      {children}
    </EditWisiwygBoxWrapper>
  ) : (
    <>{children}</>
  );
};

const Toolbar = ({ children }: { children: React.ReactNode }) => {
  return <ToolbarWrapper id="toolbar">{children}</ToolbarWrapper>;
};
