import React from 'react';
import { LabelStyled } from './style';

interface Props {
  label: string;
  children?: React.ReactNode;
}

export const Labeled: React.FC<Props> = ({ label, children }: Props) => {
  return (
    <div style={{ position: 'relative' }}>
      <LabelStyled>{label}</LabelStyled>
      {children}
    </div>
  );
};
