import { styled } from '@mui/material';

export const BlockListsWrapper = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: 'calc(100% - 8px)',
  marginTop: 16,

  '& > div': {
    margin: '8px',
  },
}));
