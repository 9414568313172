import { styled } from '@mui/system';
import { RED } from 'theme';

export const IconItemStyle = styled('div')(() => ({
  width: '30%',
  height: 100,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

export const DeleteWrapper = styled('div')(() => ({
  width: 20,
  minWidth: 20,
  height: 20,
  minHeight: 20,
  borderRadius: '100%',
  background: RED,
  position: 'absolute',
  right: '0',
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ImgWrapper = styled('img')(() => ({
  width: '100%',
  height: '100%',
  maxHeight: 100,
  borderRadius: 8,
  cursor: 'pointer',
  border: '1px solid #E4E4E4',
}));

export const IconList = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 10,
  alignItems: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap',
}));
