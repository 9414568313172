import React from 'react';
import { ILink } from './interface/global';
/* import HomeIcon from '@mui/icons-material/Home'; */
import { lightBlue, green } from '@mui/material/colors';
import { ResourceInstance } from '@devexpress/dx-react-scheduler';
import {
  AddAlert,
  AltRoute,
  AssignmentInd,
  Cottage,
  Dashboard,
  EventNote,
  FolderShared,
  Group,
  LineStyle,
  ListAlt,
  Person,
  PersonAddSharp,
  Route,
  SupervisedUserCircle,
  ViewDay,
  WhereToVote,
} from '@mui/icons-material';
import TerminalIcon from '@mui/icons-material/Terminal';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';

export type Divider = string;

type AdminLinks = Array<ILink | Divider>;

export const ADMIN_LINKS: AdminLinks = [
  /* { link: '/admin/home', title: 'Главная', icon: <HomeIcon /> }, */
  { link: '/admin/reserving', title: 'Бронирование', icon: <EventNote /> },
  { link: '/admin/profile', title: 'Профиль', icon: <Person /> },
  { link: '/admin/guests', title: 'Гости', icon: <Group /> },
  { link: '/admin/wisiwyg', title: 'Баннеры', icon: <LineStyle /> },
  {
    link: '/admin/notify',
    title: 'Общее уведомление',
    icon: <AddAlert />,
  },
];

export const DRAWER_LINKS = [
  {
    link: '/admin/guests',
    title: 'Гости',
    type: 'GUESTS',
    Icon: SupervisedUserCircle,
    menu: [
      { title: 'Список', link: '/admin/guests/list', icon: <ListAlt /> },
      {
        title: 'Создание',
        link: '/admin/guests/create',
        icon: <PersonAddSharp />,
      },
      {
        title: 'Бронирование',
        link: '/admin/guests/reserving',
        icon: <EventNote />,
      },
    ],
  },
  {
    link: '/admin/terminal',
    title: 'Терминал',
    type: 'TERMINAL',
    Icon: TerminalIcon,
    menu: [
      {
        title: 'Баннеры',
        link: '/admin/terminal/banners',
        icon: <ViewCarouselIcon />,
      },
      {
        title: 'Назначенные баннеры',
        link: '/admin/terminal/assignedbanner',
        icon: <AssignmentTurnedInIcon />,
      },
      {
        title: 'Уведомления',
        link: '/admin/terminal/notifications',
        icon: <NotificationsIcon />,
      },
      {
        title: 'Назначенные уведомления',
        link: '/admin/terminal/assignednotifications',
        icon: <EditNotificationsIcon />,
      },
      {
        title: 'Настройки',
        link: '/admin/terminal/settings',
        icon: <SettingsIcon />,
      },
    ],
  },
  {
    link: '/admin/personal',
    title: 'Персонал',
    type: 'PERSONAL',
    Icon: AssignmentInd,
    menu: [
      {
        title: 'Список',
        link: '/admin/personal/list',
        icon: <ListAlt />,
      },
      {
        title: 'Общее уведомление',
        link: '/admin/personal/notify',
        icon: <AddAlert />,
      },
    ],
  },
  {
    link: '/admin/info',
    title: 'Главная',
    type: 'INFO',
    Icon: AssignmentInd,
    menu: [
      {
        title: 'Баннеры',
        link: '/admin/info/banners',
        icon: <ListAlt />,
      },
      {
        title: 'Разделы',
        link: '/admin/info/sections',
        icon: <ViewDay />,
      },
      {
        title: 'Детальные экраны',
        link: '/admin/info/endblocks',
        icon: <Dashboard />,
      },
      {
        title: 'Общие',
        link: '/admin/info/shared',
        icon: <FolderShared />,
      },
    ],
  },
  {
    link: '/admin/trails',
    title: 'Тропы',
    type: 'TRAILS',
    Icon: Route,
    menu: [
      {
        title: 'Зоны',
        link: '/admin/trails/zone',
        icon: <Cottage />,
      },
      {
        title: 'Контрольные точки',
        link: '/admin/trails/checkpoint',
        icon: <WhereToVote />,
      },
      {
        title: 'Маршруты',
        link: '/admin/trails/routes',
        icon: <AltRoute />,
      },
    ],
  },
];

export const CALENDAR_GROUPS: ResourceInstance[] = [
  { text: 'Врач', id: 1, color: lightBlue },
  { text: 'Пациент', id: 2, color: green },
];

export const mainColor = '#278CF5';
export const mainGradient =
  'linear-gradient(239.51deg, #6FB5FF 22.34%, #488DFF 77.81%)';
export const purpleGradient =
  'linear-gradient(232.84deg, #7880FF 22.99%, #5B64EC 80.51%)';
export const lightGradient =
  'linear-gradient(143.03deg, #B5CFF7 21.47%, #A0C6FF 95.48%)';
export const greenGradient =
  'linear-gradient(244.06deg, #2ADE9B 26.41%, #19D18D 75.21%)';
