import { useTypedSelector } from 'hooks/useTypedSelector';
import { AuthState } from 'interface/login';
import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import './App.css';
import { Admin } from './pages/Admin/Admin';
import { GlobalLoader } from './ui/GlobalLoader/GlobalLoader';
import { PrivateRoute } from 'HOC/PrivateRoute/PrivateRoute';
import { Login } from 'pages/Login/Login';
import { Fade, Zoom } from '@mui/material';

const App: FC = () => {
  const { isAuth } = useTypedSelector((state) => state.user);

  if (isAuth === AuthState.WAIT_AUTH) {
    return (
      <Fade
        in
        timeout={{
          enter: 300,
          exit: 300,
          appear: 300,
        }}
      >
        <div>
          <GlobalLoader />
        </div>
      </Fade>
    );
  }

  return (
    <Zoom in timeout={300}>
      <div className="App">
        <Switch>
          <PrivateRoute
            path="/admin"
            permission={isAuth === AuthState.HAS_AUTH}
            component={Admin}
          />
          <PrivateRoute
            permission={isAuth === AuthState.NOT_AUTH}
            path="/login"
            component={Login}
          />
          <Route>
            <Redirect
              to={isAuth === AuthState.NOT_AUTH ? '/login' : '/admin'}
            />
          </Route>
        </Switch>
      </div>
    </Zoom>
  );
};

export default App;
