import React from 'react';
import { useState } from 'react';
import { LittleCloseIcon } from 'svgComponents/LittleCloseIcon';
import { MediaType } from 'types/global';
import { DeleteWrapper, IconItemStyle, ImgWrapper } from './styles';
import { Box, Typography } from '@mui/material';
import { Description } from '@mui/icons-material';

interface Props {
  icon: MediaType;
  imageType: 'IMAGE' | 'ICON' | 'DOC';
  onClick: (icon: MediaType) => void;
  onDelete: (icon: MediaType) => void;
}

export const IconItem: React.FC<Props> = ({
  icon,
  onClick,
  onDelete,
  imageType,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <IconItemStyle
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && (
        <DeleteWrapper onClick={() => onDelete(icon)}>
          <LittleCloseIcon />
        </DeleteWrapper>
      )}
      <Box
        sx={{
          position: 'absolute',
          zIndex: 2,
          bottom: 0,
          left: 0,
          width: '100%',
          height: '50%',
          background:
            'linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0) 100%)',
          pointerEvents: 'none',
          borderRadius: 1,
        }}
      />
      <Typography
        variant="body2"
        color="white"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          zIndex: 3,
          maxWidth: 260,
          maxHeight: '3em',
          paddingInline: 0.5,
          overflow: 'hidden',
          overflowWrap: 'break-word',
        }}
      >
        {icon.name}
      </Typography>
      {imageType !== 'DOC' ? (
        <ImgWrapper
          onClick={() => onClick(icon)}
          src={icon.downloadUrl}
          loading="lazy"
          alt={icon.name}
          style={{
            objectFit: `${imageType === 'IMAGE' ? 'cover' : 'contain'}`,
          }}
        />
      ) : (
        <Box onClick={() => onClick(icon)}>
          <Description fontSize="large" />
        </Box>
      )}
    </IconItemStyle>
  );
};
