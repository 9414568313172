import { Divider, IconButton } from '@mui/material';
import React from 'react';
import { LinkList } from './LinkList';
import { PoltavaLogoText } from 'svgComponents/PoltavaLogoText';
import { NavDrawerHeader, NavDrawerStyled } from './styles';
import { ChevronLeft } from '@mui/icons-material';

interface Props {
  handleDrawerClose: () => void;
  open: boolean;
}

export const NavDrawer: React.FC<Props> = ({
  handleDrawerClose,
  open,
}: Props) => {
  return (
    <NavDrawerStyled variant="temporary" anchor="left" open={open}>
      <NavDrawerHeader>
        <PoltavaLogoText />
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeft />
        </IconButton>
      </NavDrawerHeader>
      <Divider />

      <LinkList />
    </NavDrawerStyled>
  );
};
