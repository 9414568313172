import { getCurrentGuest } from 'api/guests';
import { doRequest } from 'hooks/doRequest';
import { IGuest } from 'interface/guests';
import React, { useState, useEffect } from 'react';
import { Paper, CircularProgress } from '@mui/material';
import { CardField } from './CardField';
import { QrBlock } from './QrBlock';

interface Props {
  selectedGuest: number | null;
}

export const CurrentGuestCard: React.FC<Props> = ({ selectedGuest }: Props) => {
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const [data, setData] = useState<IGuest | null>({
    id: 0,
    id1c: 0,
    name: '',
    surname: '',
    patronymic: '',
    roomNumber: '0',
    uuid: '',
  });

  const fetchGuest = async () => {
    setIsFetching(true);
    const result = await doRequest<IGuest>(getCurrentGuest, {
      id: selectedGuest,
    });
    setIsFetching(false);
    if (result.success) {
      setData(result.data);
    } else {
      /* setError(result.errors); */
    }
  };

  useEffect(() => {
    if (selectedGuest !== null) {
      fetchGuest();
    }
  }, [selectedGuest]);
  return (
    <Paper
      style={{
        minHeight: 156,
        padding: 32,
        position: 'relative',
      }}
    >
      {!isFetching && data ? (
        <>
          <CardField title="Фамилия" body={data.surname} />
          <CardField title="Имя" body={data.name} />
          <CardField title="Отчество" body={data?.patronymic} />
          <CardField title="Номер комнаты" body={data.roomNumber} />
          <CardField title="ID в 1C" body={String(data.id1c)} />
          <CardField title="ID в системе" body={String(data.id)} />

          <QrBlock guestUuid={data.uuid} />
        </>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};
