import React, { FC, useEffect, useState } from 'react';
import {
  Paper,
  Button,
  Box,
  Typography,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import { MediaType, SharedDataType } from 'types/global';
import { toast } from 'react-toastify';
import DebounceInput from 'components/CreateInfoBannerCard/DebounceInput';
import SelectDetailsScreen from 'components/CreateInfoBannerCard/SelectDetailsScreen';
import { useHistory, useParams } from 'react-router';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { ChipsWrapper, TimeWrapper } from '../style';
import SelectableChip from './SelectableChip';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  deleteSharedBlock,
  postSharedBlocks,
  updateSharedBlock,
} from 'api/sections';
import Image from 'ui/Image/Image';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import DeleteDialog from 'components/DeleteDialog/DeleteDialog';
import { NoSim } from '@mui/icons-material';

interface Props {
  allSharedData: SharedDataType[];
  onChangeData: (data: SharedDataType[]) => void;
}

const empty = {
  id: '-1',
  description: '',
  sharedIcon: {
    uuid: '',
    ext: '',
    name: '',
    uploadTime: '',
    downloadUrl: '',
  },
  detailsScreenId: -1,
  name: '',
  timeFrom: '',
  timeTo: '',
  isActive: true,
  days: [],
};

dayjs.extend(utc);
dayjs.extend(timezone);

const EditShared: FC<Props> = ({ allSharedData, onChangeData }) => {
  const [daysOfWeekSelected, setDaysOfWeekSelected] = useState<number[]>([]);
  const navigate = useHistory();
  const { blockId } = useParams<{ blockId: string }>();
  const [sharedData, setSharedData] = useState(
    allSharedData?.find((d: any) => String(d.id) === blockId) || empty
  );
  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState<MediaType | null>(null);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isSharedActive, setIsSharedActive] = useState(true);
  const daysId = [1, 2, 3, 4, 5, 6, 7];

  const handleTextChange =
    (field: keyof typeof sharedData) => (newValue: string) => {
      setSharedData((prev: any) => ({ ...prev, [field]: newValue }));
    };

  const handleSelectChange = (event: SelectChangeEvent<string | number>) => {
    setSharedData((prev: any) => ({
      ...prev,
      detailsScreenId: event.target.value,
    }));
  };

  const handleClose = () => {
    setOpenDelete(false);
  };

  useEffect(() => {
    setIcon(sharedData.sharedIcon as MediaType);
    setIsSharedActive(sharedData.isActive);
    setDaysOfWeekSelected(sharedData.days);
  }, []);

  const handleDelete = async () => {
    setOpenDelete(true);
  };

  const handleSave = async () => {
    let res: any;
    const from = dayjs(sharedData.timeFrom);
    const to = dayjs(sharedData.timeTo);
    if (
      to.diff(from) > 0 &&
      sharedData.name !== '' &&
      sharedData.detailsScreenId !== -1 &&
      sharedData.sharedIcon.name !== ''
    ) {
      setLoading(true);
      if (sharedData.id === '-1') {
        res = await postSharedBlocks(sharedData);
        const temp = [...allSharedData];
        temp.push(res.data.data as SharedDataType);
        onChangeData(temp);
      } else {
        res = await updateSharedBlock(sharedData);
        const temp = [...allSharedData];
        const index = temp.findIndex((item) => item.id === sharedData.id);
        temp[index] = sharedData as SharedDataType;
        onChangeData(temp);
      }
      if (res?.data?.data) {
        toast.success('Сохранено');
      } else {
        toast.error('Ошибка');
      }
      const currPathname = navigate.location.pathname.split('/');
      const prevPathname = currPathname
        .slice(0, currPathname.length - 1)
        .join('/');
      navigate.push(prevPathname);
      setLoading(false);
    } else if (to.diff(from) <= 0) {
      toast.error('Время начала должно быть раньше времени окончания');
    } else if (
      sharedData.name === '' ||
      sharedData.detailsScreenId === -1 ||
      sharedData.sharedIcon.name === ''
    ) {
      toast.error('Не все обязательные поля введены корректно');
    }
  };

  const handleSeletsDay = (id: number) => {
    const temp = daysOfWeekSelected;
    if (temp.includes(id)) {
      temp.splice(temp.indexOf(id), 1);
    } else {
      temp.push(id);
    }
    temp.sort();
    setSharedData((prev: any) => ({
      ...prev,
      days: temp,
    }));
    setDaysOfWeekSelected(temp);
  };

  const handleChangeTimeTo = (value: Dayjs | null) => {
    const time = value?.toDate().toString().split(' ')[4];
    setSharedData((prev: any) => ({
      ...prev,
      timeTo: `2022-12-26T${time}`,
    }));
  };

  const handleChangeTimeFrom = (value: Dayjs | null) => {
    const time = value?.toDate().toString().split(' ')[4];
    setSharedData((prev: any) => ({
      ...prev,
      timeFrom: `2022-12-26T${time}`,
    }));
  };

  const handleSetFile = (icon: MediaType) => {
    setSharedData((prev: any) => ({
      ...prev,
      sharedIcon: icon,
    }));
    setIcon(icon);
    setOpen(false);
  };

  const handleChangeActive = () => {
    if (isSharedActive) {
      setIsSharedActive(false);
      setSharedData((prev: any) => ({
        ...prev,
        isActive: false,
      }));
    } else {
      setIsSharedActive(true);
      setSharedData((prev: any) => ({
        ...prev,
        sharedIcon: true,
      }));
    }
  };

  return (
    <Paper
      style={{
        minHeight: 156,
        padding: 8,
        position: 'relative',
        marginTop: 10,
      }}
    >
      <Box
        mt={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={() => setOpen(true)}
      >
        <Typography style={{ marginRight: '16px' }}>Изображение: </Typography>
        {icon?.downloadUrl ? (
          <Image
            style={{ width: 56, height: 56 }}
            src={icon?.downloadUrl}
            alt={icon?.name}
            hover
          />
        ) : (
          <NoSim style={{ width: 56, height: 56 }} />
        )}
      </Box>
      <SelectImageDialog
        open={open}
        endPoint={'/uploads/icons'}
        setOpen={setOpen}
        onFileChange={handleSetFile}
      />
      <Box mt={2} mb={2} display="flex" flexDirection="row" alignItems="center">
        <Typography style={{ marginRight: '16px' }}>Активный: </Typography>
        <Checkbox checked={isSharedActive} onChange={handleChangeActive} />
      </Box>
      <DebounceInput
        outerValue={sharedData?.name || ''}
        setValue={handleTextChange('name')}
        label="Заголовок"
        placeholder="Заголовок"
      />
      <DebounceInput
        outerValue={sharedData?.description || ''}
        setValue={handleTextChange('description')}
        label="Подзаголовок"
        placeholder="Подзаголовок"
      />
      <SelectDetailsScreen
        value={sharedData.detailsScreenId}
        setValue={handleSelectChange}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimeWrapper>
          <DesktopTimePicker
            format="HH:mm"
            ampm={false}
            defaultValue={dayjs(sharedData.timeFrom)}
            onChange={handleChangeTimeFrom}
          />
          <DesktopTimePicker
            format="HH:mm"
            ampm={false}
            defaultValue={dayjs(sharedData.timeTo)}
            onAccept={handleChangeTimeTo}
            onChange={handleChangeTimeTo}
          />
        </TimeWrapper>
      </LocalizationProvider>
      <ChipsWrapper container gap={2} mt={2} mb={2}>
        {daysId.map((ind) => {
          return (
            <SelectableChip
              key={ind}
              id={ind}
              selectedDays={sharedData.days}
              onClick={handleSeletsDay}
            />
          );
        })}
      </ChipsWrapper>
      <Button
        style={{ marginTop: 16, marginBottom: 16 }}
        fullWidth
        color="primary"
        disabled={loading}
        onClick={handleSave}
        variant="contained"
      >
        {loading ? 'Ожидайте...' : 'Сохранить'}
      </Button>
      <DeleteDialog
        open={openDelete}
        onClose={handleClose}
        id={Number(sharedData.id)}
        name={sharedData.name}
        inBlock={true}
        onDelete={deleteSharedBlock}
      />
      {sharedData?.id !== '-1' && (
        <Button
          onClick={handleDelete}
          style={{ marginBottom: 16 }}
          fullWidth
          disabled={loading}
          variant="contained"
        >
          {loading ? 'Ожидайте...' : 'Удалить'}
        </Button>
      )}
    </Paper>
  );
};

export default EditShared;
