import React from 'react';

export const DeleteIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 5L12 10.5L6.5 5L5 6.5L10.5 12L5 17.5L6.5 19L12 13.5L17.5 19L19 17.5L13.5 12L19 6.5L17.5 5Z"
        fill="#E94242"
      />
    </svg>
  );
};
