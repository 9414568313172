import React, { useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { useCallback } from 'react';
import { useState } from 'react';
import { doRequest } from 'hooks/doRequest';
import { getCurrentGuestQr } from 'api/guests';
import { IGuestQR } from 'interface/guests';
import printJs from 'print-js';
import { CropFree, Print } from '@mui/icons-material';

interface Props {
  guestUuid: string | undefined;
}

export const QrBlock: React.FC<Props> = ({ guestUuid }: Props) => {
  const [qr, setQr] = useState<string | undefined>('');
  const [isQrFetching, setIsQrFetching] = useState<boolean>(false);

  const handleShowQrClick = useCallback(async () => {
    if (!qr) {
      setIsQrFetching(true);
      const result = await doRequest<IGuestQR>(getCurrentGuestQr, {
        guestUuid,
      });
      setIsQrFetching(false);
      if (result.success) {
        setQr(result.data?.link);
      }
    }
  }, [guestUuid, qr]);

  const handlePrintQrClick = () => {
    printJs({
      printable: 'qr',
      type: 'html',
      style: '* {transform: rotate(-90deg) translateX(23%)}',
    });
  };

  useEffect(() => {
    setQr('');
  }, [guestUuid]);

  return (
    <div>
      <div
        style={{
          fontWeight: 700,
          fontSize: 19,
          marginTop: 56,
          marginBottom: 8,
        }}
      >
        QR Код
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {!qr && (
          <Button
            disableElevation
            variant="contained"
            style={{ textTransform: 'none', marginRight: 16 }}
            startIcon={<CropFree />}
            onClick={handleShowQrClick}
          >
            Показать
          </Button>
        )}
        {qr && (
          <Button
            disableElevation
            variant="contained"
            style={{ textTransform: 'none' }}
            startIcon={<Print />}
            onClick={handlePrintQrClick}
          >
            Распечатать
          </Button>
        )}
      </div>

      {isQrFetching && (
        <div style={{ marginTop: 21, textAlign: 'center' }}>
          <CircularProgress style={{ color: 'black' }} />
        </div>
      )}
      {!!qr && (
        <div>
          <img
            id={'qr'}
            src={`${process.env.REACT_APP_SERVER_URL}${qr}`}
            style={{ width: '100%', marginTop: 32 }}
          />
        </div>
      )}
    </div>
  );
};
