import { PaperWrapper } from 'HOC/PaperWrapper/PaperWrapper';
import React, { useState, useEffect } from 'react';
import { Fade, Typography, CircularProgress } from '@mui/material';
import { doRequest } from 'hooks/doRequest';
import { getAllSections } from 'api/sections';
import SectionsList from './components/SectionsList';
import { Route, Switch } from 'react-router';
import BlockList from './components/BlockList';

const BlocksPage = ({ pathname }: { pathname: string }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);

  const getSectionsRequest = async () => {
    setIsFetching(true);
    const result = await doRequest<any | null>(getAllSections);
    setIsFetching(false);
    setData(result.data?.data);
  };

  useEffect(() => {
    getSectionsRequest();
  }, []);

  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Разделы</Typography>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <PaperWrapper>
            <Switch>
              <Route exact path={`${pathname}/`}>
                <SectionsList data={data} />
              </Route>
              <Route path={`${pathname}/:sectionId`}>
                <BlockList />
              </Route>
            </Switch>
          </PaperWrapper>
        )}
      </div>
    </Fade>
  );
};

export default BlocksPage;
