import { Divider, Grid2, Typography } from '@mui/material';
import { deleteBlock, postStartBlocks } from 'api/sections';
import EmptyBlock from 'components/EmptyBlock/EmptyBlock';
import { DeleteButton } from 'components/EmptyBlock/styles';
import AddStartBlockDialog from 'components/AddStartBlockDialog/AddStartBlockDialog';
import { doRequest } from 'hooks/doRequest';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { InfoBlockType } from 'types/global';
import { RemoveBlockDialog } from 'components/RemoveBlockDialog/RemoveBlockDialog';
import { BlockListsWrapper } from 'pages/Blocks/components/styles';
import { CancelTwoTone } from '@mui/icons-material';

export type BlockListsType = {
  data: any[];
  startBlocks: InfoBlockType[];
  handleCreateClick: () => void;
  handleClick: (id: number) => () => void;
  disabled?: boolean;
  disabledReason?: string;
  onSave?: (blockData: InfoBlockType) => any;
  disableStartBlocks?: boolean;
  onDeleteBlock?: (id: number) => void;
};

const BlockLIsts: FC<BlockListsType> = ({
  data,
  startBlocks,
  handleCreateClick,
  handleClick,
  disabled,
  disabledReason,
  disableStartBlocks,
  onDeleteBlock,
}) => {
  const { sectionId } = useParams<{ sectionId: string }>();
  const [infoBlocksId, setInfoBlocksId] = useState<number[]>([]);
  const [openAddStartBlock, setOpenAddStartBlock] = useState(false);
  const [openRemoveStartBlock, setOpenRemoveStartBlock] = useState(false);
  const [openRemoveBlock, setOpenRemoveBlock] = useState(false);

  const [blockToRemoveId, setBlockToRemoveId] = useState(0);

  useEffect(() => {
    const res: Array<number> = [];
    startBlocks?.forEach((element) => {
      res.push(element.id);
    });
    setInfoBlocksId(res);
  }, [startBlocks]);

  const handleDelete = async (id: number) => {
    const temp = [...infoBlocksId];
    temp.splice(temp.indexOf(id), 1);
    const body = { infoSectionId: sectionId, infoBlockIds: temp };
    await doRequest(postStartBlocks, body);
    window.location.reload();
  };

  const handleDeleteBlock = async (id: number) => {
    const temp = [...infoBlocksId];
    if (temp.includes(id)) {
      temp.splice(temp.indexOf(id), 1);
      const body = { infoSectionId: sectionId, infoBlockIds: temp };
      await doRequest(postStartBlocks, body);
    }
    await doRequest(deleteBlock, id);
    window.location.reload();
  };

  const handleCloseAddStartBlock = () => {
    setOpenAddStartBlock(false);
  };

  const handleCloseRemoveBlock = () => {
    setOpenRemoveBlock(false);
  };

  const handleCloseRemoveStartBlock = () => {
    setOpenRemoveStartBlock(false);
  };

  const handleOpenDialog = () => {
    setOpenAddStartBlock(true);
  };

  const handleOpenRemoveBlock = (id: number) => {
    setBlockToRemoveId(id);
    setOpenRemoveBlock(true);
  };

  const handleOpenRemoveStartBlock = (id: number) => {
    setBlockToRemoveId(id);
    setOpenRemoveStartBlock(true);
  };

  const handleSave = async (newIds: number[]) => {
    newIds.forEach((item) => {
      infoBlocksId.push(item);
    });
    setOpenAddStartBlock(false);
    const body = { infoSectionId: sectionId, infoBlockIds: infoBlocksId };
    await doRequest(postStartBlocks, body);
    window.location.reload();
  };

  return (
    <Grid2>
      {!disableStartBlocks ? (
        <>
          <Divider />
          <Typography style={{ marginTop: 8, marginLeft: 8 }} variant="h6">
            Стартовые блоки
          </Typography>
          <BlockListsWrapper>
            <AddStartBlockDialog
              open={openAddStartBlock}
              onClose={handleCloseAddStartBlock}
              startIds={infoBlocksId}
              allBlocks={data}
              onSave={handleSave}
              title="Выберите блоки, которые хотите добавить в стартовые"
            />
            <RemoveBlockDialog
              open={openRemoveStartBlock}
              onClose={handleCloseRemoveStartBlock}
              blockId={blockToRemoveId}
              onConfirm={handleDelete}
            />
            <RemoveBlockDialog
              open={openRemoveBlock}
              onClose={handleCloseRemoveBlock}
              blockId={blockToRemoveId}
              onConfirm={handleDeleteBlock}
            />
            <EmptyBlock
              disabled={disabled}
              disabledReason={disabledReason}
              onClick={handleOpenDialog}
            />
            {startBlocks !== null &&
              startBlocks?.map((item: InfoBlockType) => (
                <div key={item.id} style={{ position: 'relative' }}>
                  <DeleteButton
                    onClick={() => {
                      handleOpenRemoveStartBlock(item.id);
                    }}
                  >
                    <CancelTwoTone style={{ color: 'red' }} />
                  </DeleteButton>
                  <EmptyBlock
                    describe={item.name || item.title}
                    imgSrc={item.media?.downloadUrl}
                    onClick={handleClick(item.id)}
                  />
                </div>
              ))}
          </BlockListsWrapper>
          <Divider />
        </>
      ) : null}
      <Typography style={{ marginTop: 8, marginLeft: 8 }} variant="h6">
        Все блоки
      </Typography>
      {data ? (
        <BlockListsWrapper>
          <EmptyBlock
            disabled={disabled}
            disabledReason={disabledReason}
            onClick={handleCreateClick}
          />
          {data.map((item: InfoBlockType) => (
            <div key={item.id} style={{ position: 'relative' }}>
              <DeleteButton
                onClick={
                  onDeleteBlock
                    ? () => {
                        onDeleteBlock(item.id);
                      }
                    : () => {
                        handleOpenRemoveBlock(item.id);
                      }
                }
              >
                <CancelTwoTone style={{ color: 'red' }} />
              </DeleteButton>
              <EmptyBlock
                describe={item.name || item.title}
                imgSrc={item.media?.downloadUrl}
                key={item.id}
                onClick={handleClick(item.id)}
              />
            </div>
          ))}
        </BlockListsWrapper>
      ) : null}
    </Grid2>
  );
};

export default BlockLIsts;
