import { Box, List, ListItemIcon, ListItemText, Paper } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { DRAWER_LINKS } from '_constants';
import { ActiveListItemButton, NestedListItemButton } from './styles';

export const SideMenu: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Box
      sx={{
        display: { xs: 'none', md: 'block' },
        marginLeft: 2,
        marginTop: 3,
      }}
    >
      <Paper
        style={{
          marginLeft: 16,
          marginTop: 24,
        }}
      >
        {DRAWER_LINKS.map((item) => {
          if (pathname.includes(item.link)) {
            return (
              <List
                key={item.link}
                style={{
                  minWidth: 271,
                  flexDirection: 'column',
                  display: 'flex',
                }}
                component="nav"
                aria-labelledby="list-subheader"
              >
                {item.menu.map((menuItem) =>
                  pathname.includes(menuItem.link) ? (
                    <ActiveListItemButton
                      onClick={() => history.push(menuItem.link)}
                      key={menuItem.title}
                    >
                      <ListItemIcon>{(() => menuItem.icon)()}</ListItemIcon>
                      <ListItemText primary={menuItem.title} />
                    </ActiveListItemButton>
                  ) : (
                    <NestedListItemButton
                      onClick={() => history.push(menuItem.link)}
                      key={menuItem.title}
                    >
                      <ListItemIcon>{(() => menuItem.icon)()}</ListItemIcon>
                      <ListItemText primary={menuItem.title} />
                    </NestedListItemButton>
                  )
                )}
              </List>
            );
          }
        })}
      </Paper>
    </Box>
  );
};
