import {
  Box,
  Button,
  CircularProgress,
  Fade,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { deleteSharedBlock, getSharedBlocks } from 'api/sections';
import { PaperWrapper } from 'HOC/PaperWrapper/PaperWrapper';
import { doRequest } from 'hooks/doRequest';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { DeleteIcon } from 'svgComponents/DeleteIcon';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import EditShared from './components/EditShared';
import { SharedDataType } from 'types/global';
import DeleteDialog from 'components/DeleteDialog/DeleteDialog';

const SharedBlocks = ({ pathname }: { pathname: string }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<SharedDataType[]>([]);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [selectedSharedName, setSelectedSharedName] = useState<
    string | undefined
  >();

  const [selectedSharedId, setSelectedSharedId] = useState<
    string | undefined
  >();

  const handleClick = (id: string) => () => {
    history.push(`${history.location.pathname}/${id}`);
  };

  const onChangeData = (data: SharedDataType[]) => {
    setData(data);
  };

  const handleClickOpen = (id: string) => {
    const foundBlock = data?.find((d: SharedDataType) => d.id === id);
    setSelectedSharedName(foundBlock?.name);
    setSelectedSharedId(id);
    setOpen(true);
  };

  const handleBack = () => {
    const route = history.location.pathname.split('/');
    history.push(`${route.slice(0, route.length - 1).join('/')}`);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllSharedRequest = async () => {
    setIsFetching(true);
    const result = await doRequest<any | null>(getSharedBlocks);
    setIsFetching(false);
    setData(result.data?.data);
  };

  useEffect(() => {
    getAllSharedRequest();
  }, []);

  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Общие</Typography>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <PaperWrapper>
            <Switch>
              <Route exact path={`${pathname}`}>
                <Box>
                  <BrandButton
                    variant="contained"
                    color="primary"
                    onClick={handleClick('-1')}
                  >
                    Создать
                  </BrandButton>
                  <List>
                    {data.map((i: any) => (
                      <Box key={i.id} display="flex">
                        <ListItemButton
                          onClick={handleClick(i.id)}
                          id={i.id}
                          key={i.id}
                        >
                          <ListItemText primary={i.name} />
                        </ListItemButton>
                        <DeleteDialog
                          open={open}
                          onClose={handleClose}
                          id={Number(selectedSharedId)}
                          name={selectedSharedName}
                          inBlock={false}
                          onDelete={deleteSharedBlock}
                        />
                        <Button onClick={handleClickOpen.bind(null, i.id)}>
                          <DeleteIcon />
                        </Button>
                      </Box>
                    ))}
                  </List>
                </Box>
              </Route>
              <Route path={`${pathname}/:blockId`}>
                <Box>
                  <Box display="flex" alignItems="center" style={{ gap: 2 }}>
                    <BrandButton
                      variant="contained"
                      color="primary"
                      onClick={handleBack}
                    >
                      Назад
                    </BrandButton>
                  </Box>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <EditShared
                      onChangeData={onChangeData}
                      allSharedData={data}
                    />
                  </Box>
                </Box>
              </Route>
            </Switch>
          </PaperWrapper>
        )}
      </div>
    </Fade>
  );
};

export default SharedBlocks;
