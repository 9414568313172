import { Box, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMe } from 'redux/actions/loginActions';
import { Loader } from 'ui/Loader/Loader';

export const GlobalLoader: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, []);

  return (
    <Box height="100vh" width="100vw" bgcolor={theme.palette.primary.main}>
      <Loader />
    </Box>
  );
};
