import { SyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IOption } from '../interface/global';

type TCallback = ((value: IOption | null) => void) | null;

type TSelect = (
  event: SyntheticEvent<Element, Event>,
  value: { id: number; title: string } | null
) => void;

type THook = (
  callback: TCallback,
  initialValue?: IOption | null
) => [IOption | null, TSelect];

export const useHandleSelect: THook = (callback, initialValue) => {
  const dispatch = useDispatch();

  const [localValue, setLocalValue] = useState(initialValue ?? null);

  const handleSelect = (
    event: SyntheticEvent<Element, Event>,
    value: { id: number; title: string } | null
  ) => {
    if (initialValue !== undefined) {
      setLocalValue(value);
    }
    if (callback) {
      dispatch(callback(value));
    }
  };

  return [localValue, handleSelect];
};
