import React, { useState, useEffect } from 'react';
import { Box, TextField } from '@mui/material';
import { useCommonDebounce } from 'hooks/useCommonDebounce';
import { DetailType } from 'enums/global';

const Phone = (props: any) => {
  const [text, setText] = useState(props.text);
  const [phone, setPhone] = useState(props.phone);

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) =>
    setText(e.target.value);

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhone(e.target.value);
    setPhone(formattedPhone);
  };

  const formatPhone = (input: string): string => {
    let digits = input.replace(/[^\d+]/g, '');

    digits = digits.startsWith('+')
      ? '+' + digits.substring(1).replace(/\D/g, '')
      : digits.replace(/\D/g, '');

    const isInternational = digits.startsWith('+');
    digits = digits.substring(0, isInternational ? 12 : 11);

    const match = digits.match(
      isInternational
        ? /^(\+\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/
        : /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/
    );

    if (!match) return digits;

    let formatted = match[1] || '';
    if (match[2]) formatted += ` (${match[2]}`;
    if (match[3]) formatted += `) ${match[3]}`;
    if (match[4]) formatted += `-${match[4]}`;
    if (match[5]) formatted += `-${match[5]}`;

    return formatted;
  };

  const debouncedValueText = useCommonDebounce(text, 400);
  const debouncedValuePhone = useCommonDebounce(phone, 400);

  useEffect(() => {
    props.setData({
      text: debouncedValueText,
      phone: debouncedValuePhone,
      id: props.id,
      infoDetailsScreenId: props.infoDetailsScreenId,
      type: DetailType.phone,
    });
  }, [debouncedValueText, debouncedValuePhone]);

  return (
    <Box>
      <TextField
        value={text}
        placeholder="Текст"
        onChange={handleChangeText}
        style={{ marginBottom: 32, fontWeight: 600 }}
        fullWidth
        multiline
      />
      <TextField
        value={phone}
        placeholder="Номер телефона"
        onChange={handleChangePhone}
        style={{ marginBottom: 32, fontWeight: 600 }}
        fullWidth
      />
    </Box>
  );
};

export default Phone;
