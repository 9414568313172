import { Box, styled, InputBase } from '@mui/material';

export const CenterInput = styled(Box)(() => ({
  '& input, & textarea': {
    textAlign: 'center',
  },
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row',
  flexWrap: 'wrap',
}));

export const PriceInput = styled(InputBase)(() => ({
  '& input': {
    background: '#32BACB',
    borderRadius: 24,
    textAlign: 'center',
    padding: 8,
    color: 'white',
    fontSize: 18,
    fontWeight: 500,
  },
}));

export const HeaderInput = styled(InputBase)(() => ({
  '& input, & textarea': {
    marginRight: 16,
    fontWeight: 700,
    fontSize: 24,
    color: '#262626',
  },
}));

export const LinkInput = styled(InputBase)(({ theme }) => ({
  '& input, & textarea': {
    color: theme.palette.primary.main,
  },
}));
