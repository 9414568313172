import { createTheme } from '@mui/material';

const MAIN = '#208DFF';
const GRAY = '#93A6BC';
const LIGHT_GRAY = '#8FA2B7';
const BLACK = '#101010';
/* const WHITE = '#FFFFFF'; */
/* const YELLOW = '#FFC120'; */
export const RED = '#FF4365';

export const theme = createTheme({
  palette: {
    primary: {
      main: MAIN,
      light: '#6FB5FF',
      dark: '#006FE3',
    },
    secondary: {
      main: '#7880FF',
      light: '#B5CFF7',
    },
    success: {
      main: '#2ADE9B',
    },
    error: {
      main: RED,
    },
    info: {
      main: LIGHT_GRAY,
    },
    text: {
      primary: BLACK,
      secondary: GRAY,
    },
  },
  typography: {
    fontFamily: '"Rubik","Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
    h1: {
      fontWeight: 500,
      fontSize: 42,
      marginBottom: 16,
    },
    h2: {
      fontSize: 32,
      fontWeight: 500,
      marginBottom: 16,
    },
  },
  shape: {
    borderRadius: 12,
  },
});
