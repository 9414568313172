import React from 'react';
import { Route } from 'react-router';

import InfoBanners from 'pages/InfoBanners/InfoBanners';
import AssignedTabs from 'components/Terminal/AssignedTabs';
import EndScreens from 'pages/EndScreens/EndScreens';

import { getAllBanners, setAssignedBanners } from 'api/banner';
import {
  getAllNotifications,
  setAssignedNotification,
} from 'api/notifications';

export const TerminalPage: React.FC = () => {
  return (
    <>
      <Route exact path="/admin/terminal/banners">
        <InfoBanners endPoint="/info/terminal/admin/banners" title="Баннеры" />
      </Route>
      <Route path="/admin/terminal/assignedbanner">
        <AssignedTabs
          requestGetData={getAllBanners}
          setAssignedReq={setAssignedBanners}
          title="Выберите баннеры"
        />
      </Route>
      <Route path="/admin/terminal/notifications">
        <InfoBanners
          endPoint="/info/terminal/admin/notifications"
          endPointForDialog="/uploads/icons"
          title="Уведомления"
        />
      </Route>
      <Route path="/admin/terminal/assignednotifications">
        <AssignedTabs
          requestGetData={getAllNotifications}
          setAssignedReq={setAssignedNotification}
          title="Выберите уведомление"
        />
      </Route>

      <Route path="/admin/terminal/endblocks">
        <EndScreens pathname="/admin/terminal/endblocks" />
      </Route>
      {/* <Route>
        <Redirect to={'/admin/terminal/banners'} />
      </Route> */}
    </>
  );
};
