import React, { useEffect, useState } from 'react';

import DebounceInput from './DebounceInput';
import SelectDetailsScreen from './SelectDetailsScreen';
import SelectMediaBanner from './SelectMediaBanner';

import { Paper, Button, SelectChangeEvent } from '@mui/material';

import { deleteInfoBanner, postEditInfoBanner } from 'api/banner';
import { refetchType } from 'pages/InfoBanners/InfoBanners';
import { MediaType } from 'types/global';

import { toast } from 'react-toastify';

interface createInfoBannerCardI {
  refresh?: refetchType;
  selectedRowInfo: IData | number;
  endPointForDialog?: string;
}

interface IData {
  id: number;
  isActive: boolean;
  title: string;
  subTitle: string;
  detailsScreenId: number;
  media: MediaType;
}

const CreateInfoBannerCard: React.FC<createInfoBannerCardI> = ({
  refresh,
  selectedRowInfo,
  endPointForDialog,
}) => {
  const [data, setData] = useState<IData | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof selectedRowInfo !== 'number') {
      setData(selectedRowInfo);
    }
  }, [selectedRowInfo]);

  // Handlers group create InfoBannerCard
  const handleFileChange = (media: MediaType) => {
    setData((prev: any) => ({
      ...prev,
      media,
    }));
  };

  const handleTextChange = (field: keyof IData) => (newValue: string) => {
    setData((prev: any) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string | number>) => {
    const { target } = e;
    setData((prev: any) => ({
      ...prev,
      detailsScreenId: target.value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const res = await postEditInfoBanner(data);
    res?.data?.data ? toast.success('Сохранено') : toast.error('Ошибка');

    if (refresh) {
      refresh();
    }
    setLoading(false);
  };

  // Handlers group edit InfoBannerCard
  const handleDelete = async () => {
    setLoading(true);

    if (data) {
      const res = await deleteInfoBanner(data.id);
      res?.data?.data ? toast.success('Удалено') : toast.error('Ошибка');
    }
    if (refresh) {
      refresh();
    }

    setLoading(false);
  };

  const handleToggle = async () => {
    setLoading(true);

    const res = await postEditInfoBanner({
      ...data,
      isActive: !data?.isActive,
    });
    res?.data?.data ? toast.success('Сохранено') : toast.error('Ошибка');

    if (refresh) {
      refresh();
    }
    setLoading(false);
  };

  return (
    <Paper sx={{ p: 1, position: 'relative' }}>
      <form onSubmit={handleSubmit}>
        <SelectMediaBanner
          setValue={handleFileChange}
          value={data?.media ?? null}
          endPointForDialog={endPointForDialog}
        />
        <DebounceInput
          outerValue={data?.title || ''}
          setValue={handleTextChange('title')}
          label="Заголовок"
          placeholder="Заголовок"
        />
        <DebounceInput
          outerValue={data?.subTitle || ''}
          setValue={handleTextChange('subTitle')}
          label="Подзаголовок"
          placeholder="Подзаголовок"
        />
        <SelectDetailsScreen
          value={data?.detailsScreenId ?? ''}
          setValue={handleSelectChange}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          disabled={loading}
          type="submit"
        >
          {loading ? 'Ожидайте...' : 'Сохранить'}
        </Button>
        {data?.id !== -1 && (
          <>
            <Button
              variant="contained"
              fullWidth
              sx={{ mb: 2 }}
              disabled={loading}
              onClick={handleDelete}
            >
              {loading ? 'Ожидайте...' : 'Удалить'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ mb: 2 }}
              disabled={loading}
              onClick={handleToggle}
            >
              {data?.isActive ? 'Отключить' : 'Включить'}
            </Button>
          </>
        )}
      </form>
    </Paper>
  );
};

export default CreateInfoBannerCard;
